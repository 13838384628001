<template>
 <div class="ratgeber-share-btn btn-share btn-icon">
    <navigator-share v-if="isItMobile && webShareApiSupported" :url="link" :title="title">
        <template v-slot:clickable>
            <button class="btn btn-link" aria-label="Inhalt teilen"><span class="material-icons-outlined" aria-hidden="true">share</span></button>
        </template>
    </navigator-share>

    <a v-else :href="shareViaEmail" title="Teilen" class="btn btn-link clickSize">
        <span class="material-icons-outlined" aria-hidden="true">share</span>
    </a>

</div>

</template>
<script>
    import NavigatorShare from 'vue-navigator-share';
    import { isMobile } from 'mobile-device-detect';


    export default {
        name: "CardShareButton",
        components: {
            NavigatorShare
        },
        props: {
            link: {
                type: String,
                default: window.location.href
            },
            title: {
                type: String,
                default: 'Wolfsburger Lupe - Deine Plattform für Bildung, Freizeit und Beratung.'
            }
        },
        data() {
            return {
                isItMobile: isMobile ? true : false,
            }
        },
        computed: {
            webShareApiSupported() {
                return navigator.share;
            },
            currentPath(){
                return this.baseUrl+this.link;
            },
            baseUrl(){
                return window.location.origin;
            },
            shareViaEmail(){
                return "mailto:?body="+this.currentPath;
            },
        }
    }
</script>
<style lang="scss">
    @import '@/scss/_mixins.scss';
    @import '@/scss/_variables.scss';
    .ratgeber-share-btn{
        .btn-share {
          margin-left: 20px;

          .material-icons-outlined{
            color:#3A3A3A;
            padding: 10px;

            @media (max-width: 991px) {
                padding: 0px;
            }


            &:hover{
                padding: 10px;
                border-radius: 50%;
                border:1px solid #3A3A3A;

                @media (max-width: 991px) {
                    padding: 0px;
                    border: none;
                    color: #CECECE;
                }
            }
        }
    }
}

.clickSize {
    padding: 10px;
}

</style>